import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Container, ListGroup, ListGroupItem } from 'reactstrap';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Jumbotron from '../../components/Jumbotron';
import { SECONDARY } from '../../utils/colors';
import Thumb from '../../assets/svgs/jobs.svg';

const Backend = ({ location }) => (
  <Layout theme="blue" location={location}>
    <SEO
      title="Vagas na Central do Frete"
      description="Venha ajudar a descomplicar a forma como as empresas contratam fretes"
    />
    <Jumbotron
      Image={Thumb}
      background={SECONDARY}
      title="Vagas na Central do Frete"
      subtitle="Venha ajudar a descomplicar a forma como as empresas contratam fretes"
    />
    <Container className="pb-5 pt-4">
      <ListGroup>
        <ListGroupItem>
          <Link to="/vagas/analistaderequisitos">
            Analista de Requisitos Sênior
          </Link>
        </ListGroupItem>
        <ListGroupItem>
          <Link to="/vagas/uxuidesigner">UX/UI Designer Sênior</Link>
        </ListGroupItem>
        <ListGroupItem>
          <Link to="/vagas/phpdeveloper">PHP Developer Sênior</Link>
        </ListGroupItem>
        <ListGroupItem>
          <Link to="/vagas/frontenddeveloper">Frontend Developer Sênior</Link>
        </ListGroupItem>
        <ListGroupItem>
          <Link to="/vagas/analistadetestes">Analista de Testes Sênior</Link>
        </ListGroupItem>
      </ListGroup>
    </Container>
  </Layout>
);

Backend.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default Backend;
